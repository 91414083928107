<template>
  <div>
    <FormItem id="tree-search" v-model="tree.search" name="tree-search" class="mb-2" placeholder="Search..." />

    <div class="Tree">
      <TreeChildren
        :tree="tree"
        :parent-item="{ root: true, children: treeItems }"
        :selected="selected"
        @select="onSelectTreeItem"
        @expand="onExpandTreeItem"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FormItem from '@/components/FormItem';
import TreeChildren from '@/components/base/TreeChildren';

export default {
  name: 'Tree',
  components: {
    FormItem,
    TreeChildren
  },
  props: {
    treeItems: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      tree: {
        search: '',
        expanded: []
      }
    };
  },
  computed: {
    ...mapGetters({
      loadingAction: 'role/loadingAction'
    })
  },
  methods: {
    onExpandTreeItem(item) {
      this.$emit('expand', item);

      if (this.tree.expanded.includes(item.value)) {
        this.tree.expanded = this.tree.expanded.filter(e => e !== item.value);
      } else {
        this.tree.expanded.push(item.value);
      }
    },
    onSelectTreeItem(item) {
      this.$emit('select', item);
    }
  }
};
</script>

<style lang="scss">
.Tree-selected--partial {
  border-right: 6px solid #db8c41;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.Tree-selected {
  border-right: 6px solid #65c198;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.Tree {
  height: 800px;
  overflow-y: scroll;
  padding-right: 10px;

  &-item {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  &-item.selected {
    background-color: #eff7f3 !important;
  }

  &-item--child {
    padding-left: 20px;
  }

  &-expander {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  &-expander:hover {
    background: #f3f7ff !important;
  }

  &-label {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  &-label:hover {
    background: #f3f7ff !important;
  }
}
</style>
