<template>
  <div v-if="parentItem.children">
    <div v-for="item in parentItem.children" :key="item.value" class="Tree-item" :class="{ 'Tree-item--child': !parentItem.root }">
      <div
        class="d-flex justify-content-between bg-white my-2 rounded-sm font-w500"
        :class="{
          'Tree-selected': selected.some(s => s === item.value),
          'Tree-selected--partial':
            item.children?.length > 0 &&
            selected.some(s => item.children.some(c => c.value === s)) &&
            !item.children.every(c => selected.some(s => s._id === c.value))
        }"
      >
        <div class="d-flex flex-grow-1 align-items-center">
          <div v-if="item.children !== null" class="Tree-expander pl-2 py-2" @click="onExpandTreeItem(item)">
            <i class="fa fa-fw mr-2" :class="tree.expanded.includes(item.value) ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
          </div>
          <div class="Tree-label flex-grow-1 pl-1 pr-2 py-2" :class="{ 'pl-3': item.children === null }" @click="onSelectTreeItem(item)">
            <div><i class="fa text-primary fa-fw fa-sm mr-1" :class="`${item.icon}`"></i>{{ item.label }}</div>
            <div class="font-size-sm">
              {{ item.subLabel }}
            </div>
          </div>
          <div v-if="item.loading" class="pr-2 py-2">
            <i class="fa fa-spin fa-circle-notch" />
          </div>
        </div>
      </div>
      <div v-if="tree.expanded.includes(item.value) && item.children">
        <TreeChildren :tree="tree" :parent-item="item" :selected="selected" @select="onSelectTreeItem" @expand="onExpandTreeItem" />
      </div>
      <div v-if="tree.expanded.includes(item.value) && item.loading" class="alert alert-info py-1 px-2 mb-0 ml-4">Loading...</div>
    </div>
    <div v-if="!parentItem.loading && parentItem.children.length === 0" class="alert alert-warning py-1 px-2 mb-0 ml-4">
      <i class="fa fa-exclamation-circle fa-fw mr-2"></i>No items found
    </div>
  </div>
</template>
<script>
import TreeChildren from '@/components/base/TreeChildren';

export default {
  name: 'TreeChildren',
  components: {
    TreeChildren
  },
  props: {
    parentItem: {
      type: Object,
      required: true
    },
    selected: {
      type: Array,
      required: true,
      default: () => []
    },
    tree: {
      type: Object,
      required: true
    }
  },
  methods: {
    onExpandTreeItem(item) {
      this.$emit('expand', item);
    },
    onSelectTreeItem(item) {
      this.$emit('select', item);
    }
  }
};
</script>
