<template>
  <div>
    <FormGroup id="name" v-model="form.name" type="text" :disabled="disabled" label="Name" @input="onInputName" />
    <FormGroup id="code" v-model="form.code" type="text" :disabled="disabled" label="Code" />
    <FormGroup id="description" v-model="form.description" type="textarea" :disabled="disabled" label="Description" />
    <FormGroup
      v-if="$auth.isAdmin"
      id="systemOnly"
      v-model="form.systemOnly"
      type="radio"
      :disabled="disabled"
      label="System Only"
      :options="[
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ]"
    />

    <hr class="py-2" />

    <div v-if="!$auth.isAdmin || !form.systemOnly" class="row">
      <div class="col-md-4">
        <div class="bg-light rounded-md p-4">
          <div class="border-bottom mb-4 pb-1">
            <h5 class="mb-1">Select Items</h5>
            <p class="font-size-sm mb-0">Select the entites, assets and accounts this user is allowed to access.</p>
          </div>
          <Tree
            :selected="(form.linkedItems || []).map(item => item.item._id)"
            :tree-items="treeItems"
            @expand="onExpandTreeItem"
            @select="onSelectTreeItem"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="linkedItems">Selected Items</label>
          <div v-for="linkedItem in form.linkedItems" :key="linkedItem.item._id">
            <div class="d-flex align-items-center bg-light rounded-md mb-3 px-3 py-3">
              <div class="font-w600 flex-grow-1">
                <div class="text-uppercase text-primary font-size-sm">
                  <i
                    class="fa fa-fw"
                    :class="{
                      'fa-sitemap': linkedItem.type === 'entity',
                      'fa-building': linkedItem.type === 'asset',
                      'fa-meter': linkedItem.type === 'account'
                    }"
                  ></i>
                  {{ linkedItem.type }}
                </div>
                <div>{{ linkedItem.item.name || linkedItem.item.legalName || linkedItem.item.siteName }}</div>
                <div v-if="linkedItem.type === 'account'" class="font-size-sm text-muted">{{ linkedItem.item.meterPointNumber }}</div>
              </div>
              <div class="ml-5">
                <div class="custom-control custom-checkbox custom-control-inline">
                  <input
                    :id="`${linkedItem.item._id}-read`"
                    v-model="linkedItem.read"
                    type="checkbox"
                    class="custom-control-input"
                    :name="`${linkedItem.item._id}-read`"
                    @input="onClickReadWrite(linkedItem.item._id, 'read')"
                  />
                  <label class="custom-control-label" :for="`${linkedItem.item._id}-read`">Read</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                  <input
                    :id="`${linkedItem.item._id}-write`"
                    v-model="linkedItem.write"
                    type="checkbox"
                    class="custom-control-input"
                    :name="`${linkedItem.item._id}-write`"
                    @input="onClickReadWrite(linkedItem.item._id, 'write')"
                  />
                  <label class="custom-control-label" :for="`${linkedItem.item._id}-write`">Write</label>
                </div>
              </div>
              <a class="d-block font-w600 ml-3" href="#" @click.prevent="onRemoveLinkedItem(linkedItem)"
                ><i class="fa fa-times fa-lg text-danger"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import slugify from 'slugify';
import { mapActions, mapGetters } from 'vuex';

import Form from '@/components/forms/Form';
import FormGroup from '@/components/FormGroup';
import Tree from '@/components/base/Tree';

export default {
  name: 'PermissionForm',
  components: {
    FormGroup,
    Tree
  },
  extends: Form,
  data() {
    return {
      assetsByEntity: {},
      accountsByAsset: {},
      subMetersByAccount: {},
      loadedItems: [],
      codeEmpty: true
    };
  },
  computed: {
    ...mapGetters({
      entities: 'entity/entities'
    }),
    treeItems() {
      const entities = [...this.entities];

      entities.sort((a, b) => a.legalName.localeCompare(b.legalName));

      const parentEntities = entities.filter(entity => !entity.parentEntityId);

      const subMeterChildren = accounts =>
        accounts
          .filter(a => a.parentAccountId)
          .map(account => ({
            value: account._id,
            label: account.name,
            subLabel: account.meterPointNumber,
            loading: false,
            loaded: this.loadedItems.includes(account._id),
            type: 'account',
            icon: 'fa-meter',
            children: null
          }));

      const accountChildren = accounts =>
        accounts
          .filter(a => !a.parentAccountId)
          .map(account => ({
            value: account._id,
            label: account.name,
            subLabel: account.meterPointNumber,
            loading: false,
            loaded: this.loadedItems.includes(account._id),
            type: 'account',
            icon: 'fa-meter',
            children: subMeterChildren(this.subMetersByAccount[account._id] || [])
          }));

      const assetChildren = assets =>
        assets.map(asset => ({
          value: asset._id,
          label: asset.siteName,
          loading: false,
          loaded: this.loadedItems.includes(asset._id),
          type: 'asset',
          icon: 'fa-building',
          children: accountChildren(this.accountsByAsset[asset._id] || [])
        }));

      const entityChildren = entities =>
        entities.map(entity => ({
          value: entity._id,
          label: entity.legalName,
          loading: false,
          loaded: this.loadedItems.includes(entity._id),
          type: 'entity',
          icon: 'fa-sitemap',
          children: assetChildren(this.assetsByEntity[entity._id] || [])
        }));

      return parentEntities.map(entity => {
        // Children will either be more entities or assets
        const childEntities = entities.filter(e => e.parentEntityId === entity._id);
        const treeChildren = childEntities.length > 0 ? entityChildren(childEntities) : assetChildren(this.assetsByEntity[entity._id] || []);

        return {
          value: entity._id,
          label: entity.legalName,
          loading: false,
          loaded: this.loadedItems.includes(entity._id),
          type: entities.filter(e => e.parentEntityId === entity._id).length > 0 ? 'parentEntity' : 'entity',
          icon: 'fa-sitemap',
          children: treeChildren
        };
      });
    }
  },
  mounted() {
    this.listEntities({
      data: {
        params: {
          $select: 'parentEntityId,legalName',
          $limit: 10000
        }
      }
    });

    if (this.form.code) {
      this.codeEmpty = false;
    }
  },
  methods: {
    ...mapActions({
      listEntities: 'entity/list',
      listAssets: 'asset/list',
      listAccounts: 'account/list'
    }),
    async onExpandTreeItem(item) {
      console.log('onExpandTreeItem', item);

      if (item.type === 'entity' && item.children.length === 0 && !item.loaded) {
        item.loading = true;
        const relevantAssets = await this.listAssets({ data: { params: { entityId: item.value, $limit: 10000 } } });

        this.$set(this.assetsByEntity, item.value, relevantAssets.data);
      } else if (item.type === 'asset' && item.children.length === 0 && !item.loaded) {
        item.loading = true;
        const relevantAccounts = await this.listAccounts({ data: { params: { parentAccountId: 'null', assetId: item.value, $limit: 10000 } } });

        this.$set(this.accountsByAsset, item.value, relevantAccounts.data);
      } else if (item.type === 'account' && item.children.length === 0 && !item.loaded) {
        item.loading = true;
        const relevantSubMeters = await this.listAccounts({ data: { params: { parentAccountId: item.value, $limit: 10000 } } });

        this.$set(this.subMetersByAccount, item.value, relevantSubMeters.data);
      }

      item.loading = false;

      this.loadedItems.push(item.value);
    },
    onSelectTreeItem(item) {
      const typeMap = {
        parentEntity: 'entity',
        entity: 'entity',
        asset: 'asset',
        account: 'account',
        subMeter: 'account'
      };

      if (!this.form.linkedItems) {
        this.$set(this.form, 'linkedItems', []);
      }

      if (this.form.linkedItems.some(i => i.item._id === item.value)) {
        this.form.linkedItems = this.form.linkedItems.filter(i => i.item._id !== item.value);
      } else {
        const newItem = { _id: item.value, name: item.label };

        if (item.type === 'account') {
          newItem.meterPointNumber = item.subLabel;
        }

        this.form.linkedItems.push({
          item: newItem,
          type: typeMap[item.type],
          read: false,
          write: false
        });
      }
    },
    onRemoveLinkedItem(linkedItem) {
      console.log('onRemoveLinkedItem', linkedItem);

      this.form.linkedItems = this.form.linkedItems.filter(i => i.item._id !== linkedItem.item._id);
    },
    onInputName() {
      if (!this.form.code) this.codeEmpty = true;

      if (this.codeEmpty) {
        this.form.code = slugify(this.form.name, { lower: true, strict: true, replacement: '_' });
      }
    },
    onClickReadWrite(itemId, type) {
      const linkedItem = this.form.linkedItems.find(i => i.item._id === itemId);

      // Make sure they can't both be checked
      if (linkedItem) {
        if (type === 'read' && !linkedItem.read) {
          linkedItem.write = false;
        } else if (type === 'write' && !linkedItem.write) {
          linkedItem.read = false;
        }
      }
    }
  }
};
</script>
