var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FormItem', {
    staticClass: "mb-2",
    attrs: {
      "id": "tree-search",
      "name": "tree-search",
      "placeholder": "Search..."
    },
    model: {
      value: _vm.tree.search,
      callback: function callback($$v) {
        _vm.$set(_vm.tree, "search", $$v);
      },
      expression: "tree.search"
    }
  }), _c('div', {
    staticClass: "Tree"
  }, [_c('TreeChildren', {
    attrs: {
      "tree": _vm.tree,
      "parent-item": {
        root: true,
        children: _vm.treeItems
      },
      "selected": _vm.selected
    },
    on: {
      "select": _vm.onSelectTreeItem,
      "expand": _vm.onExpandTreeItem
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }