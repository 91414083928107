var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FormGroup', {
    attrs: {
      "id": "name",
      "type": "text",
      "disabled": _vm.disabled,
      "label": "Name"
    },
    on: {
      "input": _vm.onInputName
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "code",
      "type": "text",
      "disabled": _vm.disabled,
      "label": "Code"
    },
    model: {
      value: _vm.form.code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "description",
      "type": "textarea",
      "disabled": _vm.disabled,
      "label": "Description"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }), _vm.$auth.isAdmin ? _c('FormGroup', {
    attrs: {
      "id": "systemOnly",
      "type": "radio",
      "disabled": _vm.disabled,
      "label": "System Only",
      "options": [{
        label: 'Yes',
        value: true
      }, {
        label: 'No',
        value: false
      }]
    },
    model: {
      value: _vm.form.systemOnly,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "systemOnly", $$v);
      },
      expression: "form.systemOnly"
    }
  }) : _vm._e(), _c('hr', {
    staticClass: "py-2"
  }), !_vm.$auth.isAdmin || !_vm.form.systemOnly ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "bg-light rounded-md p-4"
  }, [_vm._m(0), _c('Tree', {
    attrs: {
      "selected": (_vm.form.linkedItems || []).map(function (item) {
        return item.item._id;
      }),
      "tree-items": _vm.treeItems
    },
    on: {
      "expand": _vm.onExpandTreeItem,
      "select": _vm.onSelectTreeItem
    }
  })], 1)]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "linkedItems"
    }
  }, [_vm._v("Selected Items")]), _vm._l(_vm.form.linkedItems, function (linkedItem) {
    return _c('div', {
      key: linkedItem.item._id
    }, [_c('div', {
      staticClass: "d-flex align-items-center bg-light rounded-md mb-3 px-3 py-3"
    }, [_c('div', {
      staticClass: "font-w600 flex-grow-1"
    }, [_c('div', {
      staticClass: "text-uppercase text-primary font-size-sm"
    }, [_c('i', {
      staticClass: "fa fa-fw",
      class: {
        'fa-sitemap': linkedItem.type === 'entity',
        'fa-building': linkedItem.type === 'asset',
        'fa-meter': linkedItem.type === 'account'
      }
    }), _vm._v(" " + _vm._s(linkedItem.type) + " ")]), _c('div', [_vm._v(_vm._s(linkedItem.item.name || linkedItem.item.legalName || linkedItem.item.siteName))]), linkedItem.type === 'account' ? _c('div', {
      staticClass: "font-size-sm text-muted"
    }, [_vm._v(_vm._s(linkedItem.item.meterPointNumber))]) : _vm._e()]), _c('div', {
      staticClass: "ml-5"
    }, [_c('div', {
      staticClass: "custom-control custom-checkbox custom-control-inline"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: linkedItem.read,
        expression: "linkedItem.read"
      }],
      staticClass: "custom-control-input",
      attrs: {
        "id": "".concat(linkedItem.item._id, "-read"),
        "type": "checkbox",
        "name": "".concat(linkedItem.item._id, "-read")
      },
      domProps: {
        "checked": Array.isArray(linkedItem.read) ? _vm._i(linkedItem.read, null) > -1 : linkedItem.read
      },
      on: {
        "input": function input($event) {
          return _vm.onClickReadWrite(linkedItem.item._id, 'read');
        },
        "change": function change($event) {
          var $$a = linkedItem.read,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = null,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(linkedItem, "read", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(linkedItem, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(linkedItem, "read", $$c);
          }
        }
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": "".concat(linkedItem.item._id, "-read")
      }
    }, [_vm._v("Read")])]), _c('div', {
      staticClass: "custom-control custom-checkbox custom-control-inline"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: linkedItem.write,
        expression: "linkedItem.write"
      }],
      staticClass: "custom-control-input",
      attrs: {
        "id": "".concat(linkedItem.item._id, "-write"),
        "type": "checkbox",
        "name": "".concat(linkedItem.item._id, "-write")
      },
      domProps: {
        "checked": Array.isArray(linkedItem.write) ? _vm._i(linkedItem.write, null) > -1 : linkedItem.write
      },
      on: {
        "input": function input($event) {
          return _vm.onClickReadWrite(linkedItem.item._id, 'write');
        },
        "change": function change($event) {
          var $$a = linkedItem.write,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = null,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(linkedItem, "write", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(linkedItem, "write", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(linkedItem, "write", $$c);
          }
        }
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": "".concat(linkedItem.item._id, "-write")
      }
    }, [_vm._v("Write")])])]), _c('a', {
      staticClass: "d-block font-w600 ml-3",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onRemoveLinkedItem(linkedItem);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-times fa-lg text-danger"
    })])])]);
  })], 2)])]) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border-bottom mb-4 pb-1"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("Select Items")]), _c('p', {
    staticClass: "font-size-sm mb-0"
  }, [_vm._v("Select the entites, assets and accounts this user is allowed to access.")])]);
}]

export { render, staticRenderFns }